
import { defineComponent } from 'vue'
import { contentTableConfig } from './config/content.config'
import { modalConfig } from './config/modal.config'
import { rules } from './config/modal.rules.config'

import PageContent from '@/components/page-content'
import PageModal from '@/components/page-modal'

import { usePageModal } from '@/hooks/usePageModal'

export default defineComponent({
  name: 'company',
  components: {
    PageContent,
    PageModal
  },
  setup() {
    const newCallback = () => {
      modalConfig.title = '新建发货公司'
    }
    const editCallback = () => {
      modalConfig.title = '编辑发货公司'
    }
    const seeCallback = () => {
      modalConfig.title = '查看发货公司'
    }
    const [
      pageModalRef,
      defaultInfo,
      handleSeeData,
      handleNewData,
      handleEditData
    ] = usePageModal(newCallback, editCallback, seeCallback)
    return {
      contentTableConfig,
      modalConfig,
      rules,
      pageModalRef,
      defaultInfo,
      handleSeeData,
      handleNewData,
      handleEditData
    }
  }
})
