export const contentTableConfig = {
  title: '发货公司列表',
  propList: [
    { prop: 'name', label: '发货公司', minWidth: '140' },
    { prop: 'Countorder', label: '订单数量', minWidth: '120' },
    {
      prop: 'createAt',
      label: '创建时间',
      minWidth: '250',
      slotName: 'createAt'
    },
    {
      prop: 'updateAt',
      label: '更新时间',
      minWidth: '250',
      slotName: 'updateAt'
    }
  ],
  showIndexColumn: true
}
