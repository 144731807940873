import { IForm } from '@/base-ui/form'

export const modalConfig: IForm = {
  title: '新建发货公司',
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: '发货公司',
      placeholder: '请输入发货公司',
      prop: 'name'
    }
  ],
  colLayout: { span: 24 },
  labelWidth: '80px'
}
